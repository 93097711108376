export default {
  'primary': '#6A47E9',

  'accent': '#6A47E9',
  'accent-100': '#3121B1',
  'accent-60': '#3121B1',
  'accent-30': '#D6BDFF',
  'accent-10': '#F0E8FF',

  // it will probably be primary color
  'gray-100': '#2F3135',
  'gray-85': '#444343',
  'gray-80': '#55575D',
  'gray-60': '#81848B',
  'gray-30': '#DCDDDE',
  'gray-20': '#E9E9E9',
  'gray-10': '#F5F5F5',
  'gray-0': '#ffffff',

  // border
  'border-10': '#E9E9E9',

  //statuses
  'created': '#55575D',
  'in_review': '#FF9F2E',
  'in_progress': '#2E90FF',
  'rejected': '#D13434',
  'approved': '#1DCA62',
  'archived': '#6A47E9',
  'to_do': '#55575D',
  'paused': '#D13434',
  'done': '#1DCA62',
  'draft': '#55575D',
  'finished': '#1DCA62',
  'skipped': '#00fff5',
  'locked': '#003c81',

  'error': '#D13434',
  'error-70': '#E32C1B',


  'primary-10': '#FFF0E5',
  'primary-60': '#FE6B00',

  'warning': '#FF9F2E',
  'warning-70': '#D97706',

  'success': '#6FCF97',
  'success-100': '#1DCA62',

  'revision_needed': '#D13434',
}
