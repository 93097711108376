<template>
  <UIDatePicker
    v-if="calendarViewType === 'btn'"
    v-model="dueDate"
    :disabled="!getPermission($route.params.project_id).design['can-change-due-date-design']"
    :disabled-date="disabledBeforeToday" @save="save"
    class="single-item-due-date"
    @close="onClose"
  />
  <v-menu
    v-else
    v-model="isMenuOpen"
    :open-on-hover="false"
    bottom
    right
    offset-y
    :nudge-bottom="6"
    :min-width="308"
    :close-on-content-click="false"
    content-class="due-date-menu"
    rounded="sm"
  >
    <template #activator="{on, attrs}">
      <slot name="activator" :on="on" :attrs="attrs" :dueDaysLeft="dueDaysLeftProject"/>
    </template>
    <v-list dense>
      <v-list-item
        dense
      >
        <UIDatePicker
          v-model="dueDate"
          :disabled="!getPermission(getProject.id).design['can-change-due-date-design']"
          :disabled-date="disabledBeforeToday" @save="save($event)"
          inline
          class="project-date-picker"
          @close="onClose"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import UIDatePicker from "@/components/UI/UIDatePicker";
import {differenceInDays, differenceInHours, format} from "date-fns";

export default {
  name: 'SingleItemDueDate',
  components: {
    UIDatePicker,
  },
  props: {
    type: {
      type: String,
      default: 'project' // project|module-style
    },
    activeStyle: {
      type: Object,
      default: null
    },
    calendarViewType: {
      type: String,
      default: 'btn' // btn|inline
    }
  },
  data() {
    return {
      dueDate: new Date().getTime(),
      isMenuOpen: false,
      rawDueDate: '',
    }
  },
  computed: {
    ...mapGetters([
      'getActiveDesignModule',
      'getPermission',
      'getProject'
    ]),
    dueDaysLeftProject() {
      // const now = new Date().setHours(0, 0, 0, 0);
      //
      // const dueData = new Date(this.rawDueDate).setHours(0, 0, 0, 0)
      const now = new Date();

      const dueData = new Date(this.rawDueDate);
      const daysDiff =  differenceInDays(dueData, now);

      if(daysDiff < 1 && daysDiff > -1) {
        const hoursLeft = differenceInHours(dueData, now);

        return {
          value: hoursLeft === 0 ? 'less than 1' : Math.abs(hoursLeft),
          prefix: `${hoursLeft < 0 ? 'Overdue:' : 'Due:'}`,
          suffix: `${hoursLeft === 0 || Math.abs(hoursLeft) === 1  ? 'hour' : 'hours'}`,
          backgroundColor: 'primary-10',
          textColor: 'error-70--text',
        }
      } else if(daysDiff <= -1) {
        return {
          value: daysDiff * -1,
          prefix: 'Overdue:',
          suffix: daysDiff > 1 ? 'days': 'day',
          backgroundColor: 'error-70',
          textColor: 'white--text',
        }
      }
      return {
        value: daysDiff,
        prefix: 'Due:',
        suffix: daysDiff > 1 ? 'days': 'day',
        backgroundColor: 'primary-10',
        textColor: 'warning-70--text',
      };
    },
  },
  mounted() {
    this.setDueDate()
  },
  watch: {
    getProject: {
      handler() {
        this.setDueDate()
      },
      deep: true
    },
    activeStyle: {
      handler() {
        this.setDueDate()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions([
      'setActiveDesignModule',
      'setDesignModules'
    ]),
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    async save(event) {
      this.isMenuOpen = false
      this.type === 'project' ? await this.updateProjectDueDate(event) : await this.updateModuleStyleDueDate(event)
    },
    setDueDate() {
      if(this.type === 'project' && this.getProject?.due_date) {
        this.dueDate = new Date(this.getProject.due_date).getTime()
        this.rawDueDate = this.getProject.due_date
      } else if(this.activeStyle?.due_date) {
        this.dueDate = new Date(this.activeStyle.due_date).getTime()
        this.rawDueDate = this.activeStyle.due_date
      }
    },
    async updateProjectDueDate(event) {
      try {
        const res = await this.$api.project.updateDueDate(this.$route.params.project_id, {due_date: format(event.value, 'yyyy-MM-dd')})

        this.$emit('updateData', res.data)

        this.$toast.open({
          message: `Due date updated`,
          type: 'success',
          position: 'top-right'
        });

      } catch (error) {
        this.$emit('updateData', this.getProject)
        console.error(error)
      }
    },
    async updateModuleStyleDueDate(event) {
      try {
        const moduleId = this.getActiveDesignModule.id
        const res = await this.$api.projectModuleDesign.updateModuleStyleDueDate(this.$route.params.project_id, moduleId, this.activeStyle.id, {due_date: format(event.value, 'yyyy-MM-dd')})
        this.$emit('updateData', res.data)
        this.$toast.open({
          message: `Due date updated`,
          type: 'success',
          position: 'top-right'
        });
      } catch (error) {
        this.setDueDate()
        console.error(error)
      }
    },
    onClose() {
      this.isMenuOpen = false
      this.setDueDate()
    }
  }
}
</script>

<style lang="scss" scoped>
.single-item-due-date {

}

.due-date-menu {
  border: 1px solid var(--v-gray-30-base);
  background: var(--v-gray-30-base);
  padding: 0px;
  max-height: 500px;
  max-width: 318px;
  box-shadow: none;

  :deep(.v-list) {
    padding: 0;
    .v-list-item {
      padding: 0;
    }
  }
}
</style>
