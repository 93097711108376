export default axios => ({

  /**
   * Get project design files list by module id
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-GetApiV1ProjectsProject_idModulesProject_module_idDesign
   * @param project_id
   * @param projectModuleId
   * @param data
   * @returns {*}
   */
  list(project_id, projectModuleId, data = {}) {
    if(!project_id) return
    const {search = ''} = data;
    const searchVal = search.replace(/#/g, '%23');
    const params = {...data, search: searchVal};
    return axios.get(`/projects/${project_id}/modules/${projectModuleId}/design`, {params});
  },

  /**
   * Get project design file by id
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-GetApiV1ProjectsProject_idModulesProject_module_idDesignId
   * @param project_id
   * @param projectModuleId
   * @param designId
   * @returns {*}
   */
  get(project_id, projectModuleId, designId) {
    return axios.get(`projects/${project_id}/modules/${projectModuleId}/design/${designId}`);
  },

  /**
   * Create project design file
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PostApiV1ProjectsProject_idModulesProject_module_idDesign
   * @param project_id
   * @param projectModuleId
   * @param data
   * @returns {*}
   */
  create(project_id, projectModuleId, data) {
    return axios.post(`projects/${project_id}/modules/${projectModuleId}/design`, data);
  },

  /**
   * Update project design file
   *
   * @link https://devapi.upsway.io/apidoc/#api-8._Project_Items_-_Design-PutApiV1ProjectsProject_idModulesProject_module_idDesignId
   * @param project_id
   * @param module_id
   * @param file_id
   * @param data
   * @returns {*}
   */
  update(project_id, module_id, file_id, data) {
    return axios.put(`projects/${project_id}/modules/${module_id}/design/${file_id}`, data);
  },

  /**
   * Update design style name
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PutApiV1ProjectsProject_idModulesProject_module_idDesignStyleStyle_id
   * @param project_id
   * @param projectModuleId
   * @param styleId
   * @param data
   * @returns {*}
   */
  updateDesignStyleName(project_id, projectModuleId, styleId, data) {
    return axios.put(`projects/${project_id}/modules/${projectModuleId}/design/style/${styleId}`, data);
  },

  /**
   * Update design style status
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PatchApiV1ProjectsProject_idModulesProject_module_idDesignIdApprove
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PatchApiV1ProjectsProject_idModulesProject_module_idDesignIdReject
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PatchApiV1ProjectsProject_idModulesProject_module_idDesignIdArchive
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PatchApiV1ProjectsProject_idModulesProject_module_idDesignReview
   * @param project_id
   * @param projectModuleId
   * @param styleId
   * @param status (approve, reject, archive, review)
   * @returns {*}
   */
  updateStatus(project_id, projectModuleId, styleId, status) {
    return axios.patch(`projects/${project_id}/modules/${projectModuleId}/design/${styleId}/${status}`);
  },

  /**
   * Update design style status
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PatchApiV1ProjectsProject_idModulesProject_module_idDesignStatus
   * @param project_id
   * @param projectModuleId
   * @param data
   * @returns {*}
   */
  updateMultipleItemsStatus(project_id, projectModuleId, data) {
    return axios.patch(`projects/${project_id}/modules/${projectModuleId}/design/status`, data);
  },

  /**
   * Delete design style status
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PatchApiV1ProjectsProject_idModulesProject_module_idDesignIdApprove
   * @param project_id
   * @param projectModuleId
   * @param styleId
   * @returns {*}
   */
  delete(project_id, projectModuleId, styleId) {
    return axios.delete(`projects/${project_id}/modules/${projectModuleId}/design/${styleId}`);
  },

  /**
   * Delete design style status
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PatchApiV1ProjectsProject_idModulesProject_module_idDesignIdApprove
   * @param project_id
   * @param projectModuleId
   * @param data
   * @returns {*}
   */
  deleteMultiple(project_id, projectModuleId, data) {
    return axios.delete(`projects/${project_id}/modules/${projectModuleId}/design/style`, {data});
  },

  /**
   * Get style list files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-GetApiV1ProjectsProject_idModulesProject_module_idDesignStyleStyle_id
   * @param project_id
   * @param projectModuleId
   * @param styleId
   * @returns {*}
   */
  filesList(project_id, projectModuleId, styleId) {
    return axios.get(`projects/${project_id}/modules/${projectModuleId}/design/style/${styleId}`);
  },

  /**
   * Delete design files
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-DeleteApiV1ProjectsProject_idModulesProject_module_idDesign
   * @param project_id
   * @param projectModuleId
   * @param data
   * @returns {*}
   */
  deleteFiles(project_id, projectModuleId, data) {
    return axios.delete(`projects/${project_id}/modules/${projectModuleId}/design`, {data});
  },

  /**
   * Update due date
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PatchApiV1ProjectsProject_idModulesProject_module_idDesignTtl-0.1.0
   * @param project_id
   * @param projectModuleId
   * @param data
   * @returns {*}
   */
  updateDueDate(project_id, projectModuleId, data) {
    return axios.patch(`projects/${project_id}/modules/${projectModuleId}/design/due-date`, data)
  },

  /**
   * Update assignee
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PatchApiV1ProjectsProject_idModulesProject_module_idDesignStyleStyle_idAssigneeFile_id
   * @param project_id
   * @param projectModuleId
   * @param styleId
   * @param fileId
   * @param data
   * @returns {*}
   *
   * projects/:project_id/modules/:project_module_id/design/style/:style_id/assignee/:file_id
   */
  updateAssignee(project_id, projectModuleId, styleId, fileId, data) {
    return axios.patch(`projects/${project_id}/modules/${projectModuleId}/design/style/${styleId}/assignee/${fileId}`, data);
  },

  /**
   * Download style files
   *
   * @param {string} project_id
   * @param {string} styleId
   * @param {{statuses: number[]}} data
   * @returns {Promise}
   */
  downloadStyleFiles(project_id, styleId, data = {}) {
    return axios.get(`projects/${project_id}/styles/${styleId}/download-files`, {responseType: "arraybuffer", params: data, skipResponseInterceptor: true})
  },

  /**
   * Download project files
   *
   * @param {string} project_id
   * @returns {Promise}
   */
  downloadFiles(project_id) {
    return axios.get(`projects/${project_id}/download-files`, {responseType: "arraybuffer", skipResponseInterceptor: true})
  },

  /**
   * Update style due date
   *
   * @link https://devapi.upsway.io/apidoc/index.html#api-8._Project_Items_-_Design-PatchApiV1ProjectsProject_idModulesProject_module_idDesignTtl-0.1.0
   * @param project_id
   * @param projectModuleId
   * @param style_id
   * @param data
   * @returns {*}
   */
  updateModuleStyleDueDate(project_id, projectModuleId, style_id, data) {
    return axios.patch(`projects/${project_id}/modules/${projectModuleId}/design/style/${style_id}/due-date`, data)
  },

})
